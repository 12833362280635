<script>
	import Trans from './Trans.svelte';
</script>

<div class="frame-inner">
	<div class="frame-header">
		<div class="btn-closed"><span>&times;</span></div>
	</div>
	<div class="frame-content">
		<div>
			<Trans
				ko="이 풀그림은 예전 도스시절의 타자풀그림의 감성에 PC통신의 감성을 더하여 제작되었습니다. 키보드 위아래 키로 메뉴 이동이 가능하고, 엔터키를 누르면 선택합니다. 뒤로 가려면 ESC를 누르십시오. 화면 및 글자의 크기는 Ctrl+확대/축소를 이용하십시오."
				en="This program is a combination of the old DOS-era typepad and PC communication sensibilities. Use the keyboard up and down keys to navigate the menu, enter to select, and ESC to go back. Use Ctrl+Zoom for screen and text size."
			/>
			<br /><br />
			▦ <Trans ko="오픈소스" en="OpenSources" /><br />
			<a href="https://cactus.tistory.com/193" target="_blank">둥근모꼴</a
			>,
			<a href="https://github.com/hurss/fonts" target="_blank">도스폰트</a
			>,
			<a href="https://github.com/naver/d2codingfont" target="_blank"
				>Naver D2Coding
			</a>, <a href="https://svelte.dev" target="_blank">Svelte</a>,
			<a href="https://jquery.com" target="_blank">jQuery</a>,
			<a href="https://github.com/e-/Hangul.js" target="_blank"
				>Hangul.js</a
			>,
			<a href="http://soundbible.com/tags-computer.html" target="_blank"
				>SoundBible</a
			>,
			<a href="https://github.com/niklasvh/html2canvas" target="_blank"
				>html2canvas
			</a>,
			<a href="https://github.com/eligrey/FileSaver.js" target="_blank"
				>FileSaver.js
			</a>
			<br /><br />
			▦ <Trans ko="제작자" en="Maker" /><br />
			zidell@gmail.com
			<br /><br />
			▦ <Trans ko="후원사" en="Sponsor" /><br />
			<Trans ko="온라인가계부 후잉" en="Whooing" />(<a
				href="https://whooing.com"
				target="_blank">https://whooing.com</a
			>)
		</div>
	</div>
</div>

<style lang="scss">
	.frame-content {
		padding: 1rem !important;
	}
</style>
